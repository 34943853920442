export const environment = {
    e2e: false,
    production: false,
    firebase: {
        apiKey: "AIzaSyDEdbyPOplnoGbweQ97QBHJ1yQPQ3Mw4u8",
        authDomain: "gcloudai.deloittecampfire.com",
        projectId: "cwl-53bca24db5a1-qa",
    },
    region: "europe-west1",
    brain_base_url: "https://gcloudai.api-test.deloitte.camp",
    breadcrumbs: {
        assetOwner: {
            aoh: "Asset Owner Hub",
            assetManagement: "Asset Management",
            principleAssetQuestions: "Principle asset questions",
            offerRequestDetail: "Offer request detail",
            assetPreview: "Preview",
        },
        store: {
            deloitteAiCatalog: "Storefront",
            checkout: "Checkout",
            standingOrderDetail: "Standing order detail",
            storefront: "CAMPfire",
            orderHistory: "Order History",
            myEntitlements: "My Entitlements"
        }
    },
    aoh_url: "https://aoh-qa.gcloudai.deloittecampfire.com/",
    store_url: "https://store-qa.gcloudai.deloittecampfire.com/",
    api_base_url: "https://gcloudai.api-test.deloitte.camp",
    authParams: ["google", "oidc.azure"],
    signInType: 'signInWithPopup',
    hasEnterpriseAssetSearch: false,
    showBarrierQuestions: false,
    skipPurchaseSuccess: true,
    skipDeploymentScreen: true,
    asset_redirect_uri: {},
    onboarding: {
        displayVideo: false,
        video_url: "https://storage.googleapis.com/dcsp-catalog-assets-public/data/products/Deloitte%20AI%20Video/Deloitte%20AI%20-%20Asset%20Owner%20Hub.mp4",
        development: {
            frontend_url: "https://storage.googleapis.com/dcsp-catalog-assets-public/data/products/Frontend_Development_Kit/Deloitte.AI%20Frontend%20Development%20Kit.pdf",
            backend_url: "https://bitbucket.de.deloitte.com/projects/AI/repos/dcsp-python-framework/browse",
            business_support_email: "mailto:campfire@deloitte.de?subject=Question%20regarding%20Deloitte%20AI%20Marketplace",
        }
    },
    barrierQuestionsAbort: {
        mailAddress: "mahorst@deloitte.de"
    },
    support: {
        ccEmail: "",
        contact: "mailto:GlobalDeloitteAi@deloitte.com?subject=Question%20regarding%20Deloitte%20AI%20Marketplace"
    },
    onboarding_options: {
        "gen_ai": {
            api_url: "https://gcloudai-geniusai.connect-test.api.deloitte.ai",
            app_url: "https://geniusai-test.gcloudai.deloittecampfire.com/workflow-selector"
        },
        "manual": {},
        "api_passthrough": {},
        "soft_onboarding": {}
    }
};
